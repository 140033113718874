import React from 'react';
import './SectionHeader.scss';

function SectionHeader(props) {
  // Render nothing if no title or subtitle
  if (!props.title && !props.subtitle) {
    return null;
  }

  const aosProps = {};
  for (const key of Object.keys(props)) {
    if (key.indexOf('data-aos') === 0) {
      aosProps[key] = props[key];
    }
  }

  return (
    <header
      className={
        'SectionHeader' + (props.className ? ` ${props.className}` : '')
      }
      {...aosProps}
    >
      {props.pretitle && (
        <p className="pretitle has-text-grey ">{props.pretitle}</p>
      )}
      {props.title && (
        <h1
          className={
            'title has-text-weight-bold' +
            (props.size ? ` is-${props.size}` : '') +
            (props.size === 1 ? ' is-size-2-mobile' : '') +
            (props.spaced ? ' is-spaced' : '')
          }
        >
          {props.title}
        </h1>
      )}

      {props.subtitle && (
        <p className={'subtitle' + (props.size > 4 ? ' is-6' : '')}>
          <span className="SectionHeader__no-classname">{props.subtitle}</span>
        </p>
      )}

      {props.text && (
        <p>
          <span className="SectionHeader__no-classname">{props.text}</span>
        </p>
      )}
    </header>
  );
}

export default SectionHeader;
