import React from 'react';
import Section from './Section';
import SectionHeader from './SectionHeader';
import SectionButton from './SectionButton';
import SectionAction from './SectionAction';

function HeroSection(props) {
  const actionText =
    typeof props.actionText === 'string'
      ? [props.actionText]
      : props.actionText;
  const actionURL =
    typeof props.actionURL === 'string' ? [props.actionURL] : props.actionURL;
  const actionType =
    typeof props.actionType === 'string'
      ? [props.actionType]
      : props.actionType;

  const buttonText =
    typeof props.buttonText === 'string'
      ? [props.buttonText]
      : props.buttonText;
  const buttonURL =
    typeof props.buttonURL === 'string' ? [props.buttonURL] : props.buttonURL;
  const buttonType =
    typeof props.buttonType === 'string'
      ? [props.buttonType]
      : props.buttonType;

  const aosProps = {};
  for (const key of Object.keys(props)) {
    if (key.indexOf('data-aos') === 0) {
      aosProps[key] = props[key];
    }
  }

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageSrc={props.backgroundImageSrc}
      backgroundImageOpacity={props.backgroundImageOpacity}
      backgroundImageColor={props.backgroundImageColor}
      {...aosProps}
    >
      <div
        className="container has-text-centered"
        data-aos="fade"
        data-aos-delay="200"
      >
        <div className="columns is-centered">
          <div className="column is-10-desktop is-8-widescreen">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
              spaced={true}
              className="has-text-centered"
            />
            <div className="buttons is-centered">
              {actionText &&
                actionText.map((text, idx) => (
                  <SectionAction
                    key={idx}
                    parentColor={props.color}
                    size="medium"
                    to={actionURL[idx]}
                    outlined={actionType && actionType[idx] === 'outlined'}
                  >
                    {text}
                  </SectionAction>
                ))}
              {buttonText &&
                buttonText.map((text, idx) => (
                  <SectionButton
                    key={idx}
                    parentColor={props.color}
                    size="medium"
                    to={buttonURL[idx]}
                    outlined={buttonType && buttonType[idx] === 'outlined'}
                  >
                    {text}
                  </SectionButton>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
