import React from 'react';
import BackgroundImage from './BackgroundImage';
import './Section.sass';

function Section(props) {
  const {
    color,
    size,
    classes = '',
    backgroundImage,
    backgroundImageSrc,
    backgroundImageOpacity,
    backgroundImageColor,
    children,
    // Passed to section element
    ...otherProps
  } = props;

  return (
    <section
      className={
        'SectionComponent hero section is-block is-relative' +
        (color ? ` is-${color}` : '') +
        (size ? ` is-${size}` : '') +
        ' ' +
        classes
      }
      {...otherProps}
    >
      {backgroundImage && (
        <BackgroundImage
          image={backgroundImage}
          opacity={backgroundImageOpacity}
          color={backgroundImageColor}
        />
      )}
      {backgroundImageSrc && (
        <BackgroundImage
          imageSrc={backgroundImageSrc}
          opacity={backgroundImageOpacity}
          color={backgroundImageColor}
        />
      )}

      {props.children}
    </section>
  );
}

export default Section;
