import React from 'react';
import { Link } from 'gatsby';
import Section from './Section';
import './Footer.scss';
import fargo from '../images/fargo-cityscape-white.svg';

function Footer(props) {
  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageOpacity={props.backgroundImageOpacity}
      classes="FooterComponent"
    >
      <div className="FooterComponent__container container">
        <div className="columns">
          <div className="column is-4">
            <Link className="brand" to="/">
              <img
                className="FooterComponent__logo"
                src={props.logo}
                alt="Logo"
              />
            </Link>

            {props.description && (
              <p className="FooterComponent__description">
                {props.description}
              </p>
            )}

            {props.copyright && (
              <p className="FooterComponent__copywrite">
                {props.copyright}
                <br />
                <Link to="/privacy">Privacy policy</Link>
              </p>
            )}
          </div>
          <div className="column is-8 is-7-widescreen is-offset-1-widescreen">
            <div className="columns">
              <div className="column is-5 is-offset-4-tablet-only">
                <div className="menu">
                  <p className="menu-label">Reach Us</p>
                  <p>
                    Horizon Real Estate Group, LLC
                    <br />
                    102 Broadway N. #202
                    <br />
                    Fargo, ND 58102
                  </p>
                </div>
              </div>
              <div className="column is-3 is-hidden-touch">
                <div className="menu">
                  <p className="menu-label">Quick Links</p>
                  <ul className="menu-list">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <Link to="/news">News</Link>
                    </li>
{/*                 <li>
                      <Link to="/closed-deals">Closed Deals</Link>
                    </li>*/}
                    {/*                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>*/}
                    <li>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/listings">All Listings</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="column is-5-tablet is-4">
                <div className="menu">
                  <p className="menu-label">Social</p>
                  <ul className="menu-list">
                    <li>
                      <a
                        href="https://www.facebook.com/Horizon-Real-Estate-Group-111687450492583/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook"></i> Facebook
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://linkedin.com/in/fargo"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-linkedin"></i> LinkedIn
                      </a>
                    </li>
                    <li>
                      <a
                        href="tel:+17014787600"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-phone-alt" /> +1 (701) 478-7600‬
                      </a>
                    </li>
                    <li>
                      <a
                        href="mailto:info@horizonfargo.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fas fa-envelope-open-text" />{' '}
                        info@horizonfargo.com‬
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="FooterComponent__scapeContainer container is-hidden-touch">
        <img
          className="FooterComponent__scape"
          src={fargo}
          alt="Silhouette of Fargo, ND"
        />
      </div>
    </Section>
  );
}

export default Footer;
