import React, { useState } from 'react';
import { Link } from 'gatsby';
import NavbarContainer from './NavbarContainer';
import './Navbar.sass';

function Navbar(props) {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavbarContainer
      spaced={props.spaced}
      fixed={props.fixed}
      color={props.color}
    >
      <div className="ContactBar has-background-primary">
        <div className="container">
          <div className="level">
            <div className="level-left is-hidden-mobile"></div>
            <div className="level-right">
              <div className="level-item">
                <i className="fas fa-phone-alt" />
                <a
                  href="tel:+17014787600"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +1 (701) 478-7600‬
                </a>
              </div>
              <div className="level-item">
                <i className="fas fa-envelope-open-text" />
                <a
                  href="mailto:info@horizonfargo.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@horizonfargo.com‬
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <img className="image" src={props.logo} alt="Logo"></img>
            </Link>
          </div>
          <div
            className={'navbar-burger burger' + (menuOpen ? ' is-active' : '')}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className={'navbar-menu' + (menuOpen ? ' is-active' : '')}>
          <div className="navbar-end">
            <Link
              className="navbar-item"
              activeClassName="is-active"
              to="/about"
            >
              About
            </Link>
            <Link
              className="navbar-item"
              activeClassName="is-active"
              to="/services"
            >
              Services
            </Link>
            <Link
              className="navbar-item"
              activeClassName="is-active"
              to="/news"
            >
              News
            </Link>
{/*            <Link
              className="navbar-item"
              activeClassName="is-active"
              to="/closed-deals"
            >
              Closed Deals
            </Link>*/}
            {/*            <Link className="navbar-item" activeClassName="is-active" to="/faq">
              FAQ
            </Link>*/}
            <Link
              className="navbar-item"
              activeClassName="is-active"
              to="/contact-us"
            >
              Contact Us
            </Link>
            <div className="navbar-item">
              <Link className="button is-primary" to="/listings">
                All Listings
              </Link>
            </div>
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
