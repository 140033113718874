import React from 'react';
import './BackgroundImage.scss';

function BackgroundImage(props) {
  return props.imageSrc ? (
    <div
      className="BackgroundImageSrc"
      style={{
        '--image': `url(${props.imageSrc})`,
        '--opacity': props.opacity,
        '--color': props.color ? props.color : 'transparent',
      }}
    />
  ) : props.image ? (
    <div
      className="BackgroundImage"
      style={{
        '--opacity': props.opacity,
        '--color': props.color ? props.color : 'transparent',
      }}
    >
      {props.image}
    </div>
  ) : null;
}

export default BackgroundImage;
