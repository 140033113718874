import React, { useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

function NavbarContainer(props) {
  const [isCompact, setIsCompact] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      // `y` is negative scolling down
      const isScrollingDown = currPos.y < prevPos.y;
      const shouldBeCompact = isScrollingDown && currPos.y < -50;
      if (shouldBeCompact !== isCompact) setIsCompact(shouldBeCompact);
    },
    [isCompact]
  );

  return (
    <nav
      className={
        'navbar' +
        (props.color ? ` is-${props.color}` : '') +
        (props.spaced ? ' is-spaced' : '') +
        (props.fixed ? ' is-fixed-top' : '') +
        (isCompact ? ' is-compact' : '')
      }
    >
      {props.children}
    </nav>
  );
}

export default NavbarContainer;
