import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Navbar from './Navbar';
import Footer from './Footer';
import Logo from '../images/logo.svg';
import LogoWhite from '../images/logo-white.svg';

const Layout = ({ children }) => {
  const AOS = useRef();

  useEffect(() => {
    AOS.current = require('aos');
    AOS.current.init({
      once: true,
      disable: () => window.innerWidth < 1024,
      duration: 600,
      delay: 400,
    });
    AOS.current.refresh();
  }, []);

  useEffect(() => {
    if (AOS.current) {
      AOS.current.refresh();
    }
  });

  return (
    <>
      <Navbar color="white" spaced={true} fixed={true} logo={Logo} />
      {children}
      <Footer
        color="primary"
        size="medium"
        backgroundImage=""
        backgroundImageOpacity={1}
        description=""
        copyright={`© ${new Date().getFullYear()} Horizon Real Estate Group`}
        logo={LogoWhite}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
